import $ from "jquery";
import { I18n } from "react-redux-i18n";
import SortedSet from "sortedset";
import SettingsEditor from "../components/SettingsEditor";
import languages, { locals } from "../langs/languages";
import auth, { select, getLocale, getGlobal, get, post, ajaxCallStart, ajaxCallEnd, loadMyImage, navigateToLogin, messageDialog, switchServer, del, patch, updateServerUrl, setGlobalServer, featureEnabled, _logError } from "./auth";
import orderListUtil, { getTable, tmplparams } from "./order-list-util";
import { getMenuItemAdditions, printQRCode, selectTableOccupation } from "./tables-overview";
import React from "react";
import ReactDOM from "react-dom";
import { restaurant_settings_schema, restaurant_settings_schema_ui_schema } from "./restaurant_settings_schema";

/*eslint-disable-next-line no-unused-vars*/
const { local, admin_local } = languages;

export let admin = {
  tableMatesMap: new Map(),
  tableMates: []
};

export const allRoles = ["admin", "superadmin", "cook", "waiter", "store", "printerserver", "tester", "finance"];
export const allNormalRoles = ["admin", "cook", "waiter", "store", "finance"];
admin.allRole = allRoles;

if (typeof localStorage.mateSelected === "undefined") localStorage.mateSelected = "All";

export const deletedMenuItems = [];

export const colors = auth.colors;

$("body").keydown(function (e) {
  if (e.isDefaultPrevented()) return;
  /*
  if (typeof keyDownHandler != 'undefined') {
    keyDownHandler(e);
    if (e.isDefaultPrevented())
      return;
  }
  */
  if (e.target === $("body").get(0) && !(orderListUtil.help && orderListUtil.help.on))
    switch (e.which) {
      case 27: // esc
        $("#back").click();
        break;

      default:
        return; // exit this handler for other keys
    }
});

function getAllRestaurants(handler, errorHandler) {
  get("adminService/getRestaurants", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getAllRestaurants = getAllRestaurants;

function getAllRestaurants2(handler, errorHandler) {
  get("adminService/getRestaurants2", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getAllRestaurants2 = getAllRestaurants2;

export const getRolesByRestaurants = (restaurant, handler, errorHandler) => {
  get("adminService/" + restaurant + "/getRolesByRestaurant", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
admin.getRolesByRestaurants = getRolesByRestaurants;

var tawkTo = false;
export const getMyStatus = (handler, failHandler) => {
  if (typeof localStorage.restaurantSelected === "undefined" || localStorage.restaurantSelected === "undefined") {
    console.log("localStorage.restaurantSelected changed to 0");
    localStorage.restaurantSelected = 0;
  }
  get("adminService/" + localStorage.restaurantSelected + "/getMyStatus?frame=" + auth.isIncludedInFrame + "&lang=" + localStorage.language + "&instance=" + localStorage.instance, localStorage.id)
    .done(function (data) {
      try {
        //if (data.gitlabSecureToken)
        //	feedback();
        // eslint-disable-next-line eqeqeq
        /*
        if (data.restaurant_global_id == 2 && tawkTo == false) {
          tawkTo = true;
          var Tawk_API = Tawk_API || {};
          (function () {
            var s1 = document.createElement("script"),
              s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/5dc4670b1936a27a715fcddc/default";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
          })();
        }*/

        localStorage.id = data.id;
        localStorage.email = data.email;
        localStorage.name = data.name;
        localStorage.surname = data.surname;
        localStorage.removeItem("restaurantSelected");
        if (data.is_global_server === true) {
          console.log("set global server to", auth.server);
          setGlobalServer(auth.server);

        }
        delete localStorage.restaurantSelected;
        delete localStorage.globalId;
        if (data.restaurant_id && data.restaurant_id > 0) {
          // eslint-disable-next-line eqeqeq
          if (localStorage.restaurantSelected != data.restaurant_id) {
            localStorage.restaurantSelected = data.restaurant_id;
            localStorage.globalId = data.restaurant_global_id;
            // eslint-disable-next-line no-restricted-globals
            get("login/setRestaurant/" + localStorage.restaurantSelected, location.reload);
          }
        } else {
          // eslint-disable-next-line eqeqeq
          if (localStorage.restaurantSelected) {
            localStorage.restaurantSelected = 0;
            // eslint-disable-next-line no-restricted-globals
            get("login/setRestaurant/" + localStorage.restaurantSelected, location.reload);
          }
        }

        if (!auth.isIncludedInFrame && data.test_mode && $("body > #test_mode").length === 0) {
          $("body").addClass("test-mode");
        } else {
          $("body").removeClass("test-mode");
        }

        loadMyImage();
        // eslint-disable-next-line eqeqeq
        if (localStorage.restaurantSelected > 0 && (localStorage.restaurantSelected == data.restaurant_id || (auth.myStatus && auth.myStatus.restaurant_modification_timestamp !== data.restaurant_modification_timestamp))) {
          //console.log(data);
          get("adminService/" + localStorage.restaurantSelected + "/getRestaurantSettings?lang=" + localStorage.language + "&globalId=" + data.restaurant_global_id).done(function (d) {
            Object.keys(d).forEach(function (key) {
              data[key] = d[key];
            });
            auth.setMyStatus(data);
            const appAccess = data.restaurant_settings["enabled-features"].appAccess ? data.restaurant_settings["enabled-features"].appAccess.enabled : false;
            //console.log("App access " + appAccess)
            if (!appAccess && auth.device.platform !== "browser" && data.roles.filter(r => r.role === "superadmin").length === 0) {
              messageDialog(local.information, admin_local.you_are_not_authorized_to_access_this_restaurant_with_an_app).done(() => {
                /*
                auth.logout(true).done(() => {
                  delete localStorage.globalId;
                  navigateToLogin();
                })
                */
              });
            }
            handler(data);
          }).fail(result => {
            if (result.status === 403) {
              localStorage.restaurantSelected = undefined;
              auth.server = auth.globalserver;
            }
            console.log(result);
          });
        } else {
          auth.setMyStatus(data);
          handler(data);
        }
      } catch (ex) {
        console.log("Exception:" + ex);
        console.log(ex);
        console.log(ex.stack);
      }
    })
    .fail(data => {
      console.log("getMyStatus FAILED from server " + auth.server);
      console.log(localStorage.restaurantSelected)
      const serverold = auth.server;
      updateServerUrl().always(() => {
        if (auth.server != serverold && localStorage.restaurantSelected !== "0") {
          localStorage.restaurantSelected = 0;
          getMyStatus(handler, failHandler);
          return;
        }

        console.log("failed to get status from server");
        if (auth.server !== auth.globalserver && typeof localStorage.globalId != "undefined") {
          const s = auth.server;
          localStorage.server = auth.server = auth.globalserver;
          switchServer({ globalId: localStorage.globalId, serverUrl: s }).done(() => {
            //eslint-disable-next-line no-restricted-globals
            location.reload();
          }).fail(() => {
            if (failHandler !== undefined) failHandler(data);
            console.log("NEED TO LOGIN");
            navigateToLogin();
          });
          auth.ajaxError = false;
          return;
        }

        if (auth.server !== auth.globalserver) {
          if (data.status === 200 && data.responseText !== '') {
            const json = JSON.parse(data.responseText);
            if (json.is_global_server === true) {
              setGlobalServer(auth.server);
              if (failHandler !== undefined) failHandler(data);
              return;
            }

          }
          localStorage.server = auth.globalserver;
          auth.server = auth.globalserver;
        }
        if (failHandler !== undefined) failHandler(data);
        console.log("Failed to get my status");
      });
    });
};
admin.getMyStatus = getMyStatus;

let menuItemMap = {};
function updateMenuItemMap(categories) {
  categories.forEach(function (item) {
    if (item.entityType.indexOf("Category") === -1) {
      menuItemMap["item_" + item.id] = item;
    } else {
      menuItemMap["category_" + item.id] = item;
      if (item.children && item.children.length > 0) updateMenuItemMap(item.children);
    }
  });
}
admin.updateMenuItemMap = updateMenuItemMap;
export const getMenuItems = (handler, lang, deleted = false, active = false) => {
  get(
    "restaurantService/" + localStorage.restaurantSelected + "/getMenuItems?" + (typeof lang === "undefined" ? "" : "lang=" + lang) + "&deleted=" + deleted + "&active=" + active,
    localStorage.restaurantSelected
  ).done(function (data) {
    menuItemMap = {};
    console.log("Update product map")
    admin.updateMenuItemMap(data.children);
    if (data === null) {
      console.log("Failed to get menu categories!");
    } else {
      handler(data);
    }
  });
}
admin.getMenuItems = getMenuItems;

export const getGlobalUsersContaining = (restaurant_global_id, contains, handler, errorHandler) => {
  getGlobal("adminService/" + restaurant_global_id + "/getUsersContaining?contains=" + encodeURIComponent(contains), localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
admin.getGlobalUsersContaining = getGlobalUsersContaining;

function getActiveMenuItems(handler, lang) {
  if (localStorage.restaurantSelected > 0)
    get("adminService/getMenuItems?restaurant=" + localStorage.restaurantSelected + "&lang=" + localStorage.language, localStorage.restaurantSelected, undefined, undefined, false).done(function (data) {
      if (data === null) {
        console.log("Failed to get menu categories!");
      } else {
        menuItemMap = {};
        function updateMenuItemMap(categories) {
          categories.forEach(function (item) {
            if (item.entityType.indexOf("Category") === -1) {
              menuItemMap["item_" + item.id] = item;
            } else {
              menuItemMap["category_" + item.id] = item;
              if (item.activeMenuCategories && item.activeMenuCategories.length > 0) updateMenuItemMap(item.activeMenuCategories);
              if (item.activeMenuItems && item.activeMenuItems.length > 0) updateMenuItemMap(item.activeMenuItems);
              if (item.children && item.children.length > 0) updateMenuItemMap(item.children);
            }
          });
        }
        updateMenuItemMap(data.activeMenuCategories);
        handler(data);
      }
    });
}
admin.getActiveMenuItems = getActiveMenuItems;

function getMenuItemsByIds(ids, handler, errorHandler) {
  if (ids.length === 0) handler([]);
  else
    post("adminService/" + localStorage.restaurantSelected + "/getMenuItemsByIds?lang=" + localStorage.language, ids).done(function (data) {
      data.forEach(function (menuItem) {
        menuItemMap["item_" + menuItem.id] = menuItem;
      });
      resultHandler(data, handler, errorHandler);
    });
}
admin.getMenuItemsByIds = getMenuItemsByIds;

export const addMenuItem = (menuItem) => {
  menuItemMap["item_" + menuItem.id] = menuItem;
}
export const getMenuItem = (id, nocopy) => {
  var menuItem = null;
  menuItem = menuItemMap["item_" + id];
  if (typeof menuItem === "undefined" || menuItem === null || menuItem === "") {
    if (typeof deletedMenuItems != "undefined")
      deletedMenuItems.forEach(function (item) {
        // eslint-disable-next-line eqeqeq
        if (item.id == id) menuItem = item;
      });
    if (!menuItem) {
      return {
        id: id,
        type: "deleted",
        name: local.deleted_or_not_found + "(" + id + ")",
        availableQuantities: []
      };
    }
  }
  if (typeof nocopy != "undefined" && nocopy === true) return menuItem;
  var val = Object.assign({}, menuItem);
  delete val.image;
  return val;
};
admin.getMenuItem = getMenuItem;

export const getLabel = (id) => {
  return auth.myStatus.restaurant_labels ? auth.myStatus.restaurant_labels.find(l => l.id === id) : undefined;
};

export const getMenuCategory = id => {
  var menuCategory = null;
  menuCategory = menuItemMap["category_" + id];
  if (typeof menuCategory === "undefined" || menuCategory === null || menuCategory === "") {
    if (typeof deletedMenuItems != "undefined")
      deletedMenuItems.forEach(function (item) {
        // eslint-disable-next-line eqeqeq
        if (item.id == id) menuCategory = item;
      });
    if (menuCategory === null || menuCategory === "") {
      return {
        id: id,
        type: "deleted",
        name: local.deleted_or_not_found + "(" + id + ")"
      };
    }
  }
  if (!menuCategory)
    return null;
  var val = Object.assign({}, menuCategory);
  delete val.image;
  return val;
};
admin.getMenuCategory = getMenuCategory;

export const menuItemReferences = (item, type = ['mandatory', 'included', 'optional']) => {
  const res = [];
  if (item && item.availableQuantities)
    Object.values(menuItemMap).forEach(menuItem => menuItem.menuItemAdditions.filter(it => type.indexOf(it.additionType) !== -1 && it.addition.id === item.id).forEach(item => res.push({ menuItem: menuItem, addition: item })));
  else if (item)
    Object.values(menuItemMap).forEach(menuItem => menuItem.menuCategoryAdditions.filter(it => type.indexOf(it.additionType) !== -1 && it.addition.id === item.id).forEach(item => res.push({ menuItem: menuItem, addition: item })));
  return res;
}

var getMenuItemByIdQueue = [];

export const getMenuItemById = function (id, handler, errorHandler) {
  getMenuItemByIdQueue.push({ id, handler, errorHandler });
  if (getMenuItemByIdQueue.length == 1) {
    admin._getMenuItemById();
  }
};
admin.getMenuItemById = getMenuItemById;

admin._getMenuItemById = function () {
  var task = getMenuItemByIdQueue[0];
  get("restaurantService/" + localStorage.restaurantSelected + "/getMenuItem/" + task.id, localStorage.restaurantSelected).done(function (data) {
    resultHandler(data, task.handler, task.errorHandler);
    getMenuItemByIdQueue.shift();
    if (getMenuItemByIdQueue.length > 0)
      admin._getMenuItemById();
  });
};

export const getMenuCategoryById = function (id, handler, lang) {
  get("restaurantService/" + localStorage.restaurantSelected + "/getMenuCategory/" + id, localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get menu item!");
    } else {
      handler(data);
    }
  });
};
admin.getMenuCategoryById = getMenuCategoryById;


function getDrinks(handler, lang) {
  get("restaurantService/" + localStorage.restaurantSelected + "/getDrinks" + (typeof lang === "undefined" ? "" : "?lang=" + lang), localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get menu categories!");
    } else {
      handler(data);
    }
  });
}
admin.getDrinks = getDrinks;

function getMeals(handler, lang) {
  get("restaurantService/" + localStorage.restaurantSelected + "/getMeals" + (typeof lang === "undefined" ? "" : "?lang=" + lang), localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get menu categories!");
    } else {
      handler(data);
    }
  });
}
admin.getMeals = getMeals;

admin.ordersCache = [];
function getUnprocessedOrders(type, handler) {
  admin.ordersCache = [];

  /*
  if (typeof localStorage.ordersCache != "undefined") {
    admin.ordersCache = JSON.parse(localStorage.ordersCache);
  }*/

  var ordersCacheMap = {};
  var orderIds = [];
  admin.ordersCache.forEach(function (order, ind) {
    ordersCacheMap[order.id] = order;
    order.keepOrder = false;
    orderIds.push({
      id: order.id,
      timestamp: order.modificationTimeStamp
    });
  });

  //orderIds = [];

  post("adminService/" + localStorage.restaurantSelected + "/getUnprocessedOrders?lang=" + localStorage.language + "&platform=" + auth.device.platform, orderIds)
    .done(function (data) {
      if (data === null) {
        console.log("Failed to get orders!");
      } else {
        deletedMenuItems.length = 0;
        var deletedMenuItemIds = [];
        data.forEach(function (v) {
          if (typeof v === "number") {
            v = ordersCacheMap[v];
          } else {
            ordersCacheMap[v.id] = v;
          }
          v.keepOrder = true;
          var childs = [];
          v.childOrders.forEach(function (child) {
            if (child.recordState !== "DELETED") childs.push(child);
          });
          v.childOrders = childs;

          var m = getMenuItem(v.menuItem.id);
          if (m.type === "deleted") {
            if (deletedMenuItemIds.indexOf(v.menuItem.id) === -1) deletedMenuItemIds.push(v.menuItem.id);
          }
          v.childOrders.forEach(function (c) {
            var m = getMenuItem(c.menuItem.id);
            if (m.type === "deleted") {
              if (deletedMenuItemIds.indexOf(v.menuItem.id) === -1) deletedMenuItemIds.push(v.menuItem.id);
            }
          });
        });

        data = [];
        Object.values(ordersCacheMap).forEach(function (order) {
          if (order.keepOrder) {
            delete order.keepOrder;
            data.push(order);
          }
        });

        admin.getMenuItemsByIds(deletedMenuItemIds, function (deletedItems) {
          deletedMenuItems.length = 0;
          deletedItems.forEach(d => {
            deletedMenuItems.push(d);
          });
          handler(data);
        });
      }
    })
    .fail(function (data) {
      console.log(data);
    });
}
admin.getUnprocessedOrders = getUnprocessedOrders;

function resync(type, handler, recovery = false) {
  admin.ordersCache = [];

  /*
  if (typeof localStorage.ordersCache != "undefined") {
    admin.ordersCache = JSON.parse(localStorage.ordersCache);
  }*/

  var ordersCacheMap = {};
  var orderIds = [];
  admin.ordersCache.forEach(function (order, ind) {
    ordersCacheMap[order.id] = order;
    order.keepOrder = false;
    orderIds.push({
      id: order.id,
      timestamp: order.modificationTimeStamp
    });
  });

  //orderIds = [];

  post("adminService/" + localStorage.restaurantSelected + "/resync?lang=" + localStorage.language + "&platform=" + auth.device.platform, orderIds, undefined, !recovery, undefined, undefined, 60000)
    .done(function (data) {
      if (data === null) {
        console.log("Failed to get orders!");
      } else {

        data.tableOccupations.forEach(t => {
          if (t.tableMates.length && t.tableMates.filter(m => m.active).length === 0)
            t.tableMates[0].active = true;
          if (t.restaurantTables.length && t.restaurantTables.filter(t => t.active).length === 0)
            t.restaurantTables[0].active = true;
        })

        menuItemMap = {};
        function updateMenuItemMap(categories) {
          categories.forEach(function (item) {
            if (item.entityType.indexOf("Category") === -1) {
              menuItemMap["item_" + item.id] = item;
            } else {
              menuItemMap["category_" + item.id] = item;
              if (item.activeMenuCategories && item.activeMenuCategories.length > 0) updateMenuItemMap(item.activeMenuCategories);
              if (item.activeMenuItems && item.activeMenuItems.length > 0) updateMenuItemMap(item.activeMenuItems);
              if (item.children && item.children.length > 0) updateMenuItemMap(item.children);
            }
          });
        }
        updateMenuItemMap(data.activeMenuCategories);

        deletedMenuItems.length = 0;
        var deletedMenuItemIds = [];
        data.orders.forEach(function (v) {
          if (typeof v === "number") {
            v = ordersCacheMap[v];
          } else {
            ordersCacheMap[v.id] = v;
          }
          v.keepOrder = true;
          var childs = [];
          v.childOrders.forEach(function (child) {
            if (child.recordState !== "DELETED") childs.push(child);
          });
          v.childOrders = childs;

          var m = getMenuItem(v.menuItem.id);
          if (m.type === "deleted") {
            if (deletedMenuItemIds.indexOf(v.menuItem.id) === -1) deletedMenuItemIds.push(v.menuItem.id);
          }
          v.childOrders.forEach(function (c) {
            var m = getMenuItem(c.menuItem.id);
            if (m.type === "deleted") {
              if (deletedMenuItemIds.indexOf(v.menuItem.id) === -1) deletedMenuItemIds.push(v.menuItem.id);
            }
          });
        });

        data.orders = [];
        Object.values(ordersCacheMap).forEach(function (order, ind) {
          if (order.keepOrder) {
            delete order.keepOrder;
            var found = false;
            orderListUtil.newOrders.forEach((o, ind2) => {
              if (found) return;
              if (order.id > 0 && o.tempId.startsWith(order.tempId)) {
                found = true;
                //orderListUtil.newOrders.splice(ind2, 1);
              }
            })
            data.orders.push(order);
          }
        });

        admin.getMenuItemsByIds(deletedMenuItemIds, function (deletedItems) {
          deletedMenuItems.length = 0;
          deletedItems.forEach(d => {
            deletedMenuItems.push(d);
          });
          handler(data);
        });
      }
    })
    .fail(function (data) {
      console.log(data);
    });
}
admin.resync = resync;

function getOrder(tableOccupation, order, handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getOrder?tableOccupation=" + tableOccupation + "&order=" + order + "&lang=" + localStorage.language).done(function (data) {
    if (data === null) {
      console.log("Failed to get orders!");
    } else {
      handler(data);
    }
  });
}
admin.getOrder = getOrder;

function processchangeStateByTableAndMenuItem(t, previousState, state, timing, comment, type, handler, errorHandler) {
  if (typeof type === "undefined") type = [];
  t = $(t);
  var tableOccupation = t.attr("tableOccupation");
  var orderIds = typeof t.attr("orders") === "undefined" ? -1 : t.attr("orders");
  // eslint-disable-next-line no-useless-concat
  var url =
    "adminService/" +
    localStorage.restaurantSelected +
    "/changeOrderState" +
    "?lang=" +
    localStorage.language +
    "&tableOccupation=" +
    tableOccupation +
    "&productionLines=" +
    (typeof localStorage.productionLineSelected !== "undefined" ? localStorage.productionLineSelected : "") +
    "&state=" +
    state +
    "&comment=" +
    comment +
    "&orderIds=" +
    orderIds;
  post(url, type).done(function (data) {
    if (data === null) {
      console.log("Failed to get drink categories!");
    } else if (data.error) {
      resultHandler(data, handler, errorHandler);
    } else {
      handler(data);
    }
  });
}
admin.processchangeStateByTableAndMenuItem = processchangeStateByTableAndMenuItem;

function processchangeStateByOrderIds(tableOccupation, orderIds, state, handler, errorHandler) {
  var type = [];
  // eslint-disable-next-line no-useless-concat
  var url =
    "adminService/" +
    localStorage.restaurantSelected +
    "/changeOrderState" +
    "?lang=" +
    localStorage.language +
    "&tableOccupation=" +
    tableOccupation +
    "&productionLines=" +
    (typeof localStorage.productionLineSelected !== "undefined" ? localStorage.productionLineSelected : "") +
    "&state=" +
    state +
    "&comment=" +
    "&orderIds=" +
    orderIds;
  post(url, type).done(function (data) {
    if (data === null) {
      console.log("Failed to get drink categories!");
    } else if (data.error) {
      resultHandler(data, handler, errorHandler);
    } else {
      handler(data);
    }
  });
}
admin.processchangeStateByOrderIds = processchangeStateByOrderIds;

function changeStateForOrders(tableOccupationId, orderIds, state, productionLine, handler, errorHandler) {
  // eslint-disable-next-line no-useless-concat
  var url =
    "adminService/" +
    localStorage.restaurantSelected +
    "/changeOrderState" +
    "?lang=" +
    localStorage.language +
    "&tableOccupation=" +
    tableOccupationId +
    "&productionLines=" +
    productionLine +
    "&state=" +
    state +
    "&comment=" +
    "&orderIds=" +
    orderIds;
  post(url, []).done(function (data) {
    if (data === null) {
      console.log("Failed to get drink categories!");
    } else if (data.error) {
      resultHandler(data, handler, errorHandler);
    } else {
      handler(data);
    }
  });
}
admin.changeStateForOrders = changeStateForOrders;

function orderSelectedMenuItems(tableOccupation, tableNumber, accepted, orderIds, print, handler) {
  // eslint-disable-next-line no-useless-concat
  var url = "adminService/" + localStorage.restaurantSelected + "/orderSelectedOrders" + "?lang=" + localStorage.language + "&tableOccupation=" + tableOccupation + "&tableNumber=" + tableNumber + "&accepted=" + accepted + "&print=" + print;
  post(url, orderIds).done(function (data) {
    if (data === null) {
      console.error("Failed to order selected menu items!");
    } else {
      handler(data);
    }
  });
}
admin.orderSelectedMenuItems = orderSelectedMenuItems;

export const getRestaurants = (handler) => {
  get("adminService/getRestaurants", localStorage.id).done(function (data) {

    if (data === null) {
      console.log("Failed to get restaurants!");
    } else {
      handler(data);
    }
  });
}
admin.getRestaurants = getRestaurants;

function getGlobalRestaurants(handler, errorHandler) {
  getGlobal("adminService/getRestaurants", localStorage.id, undefined, false)
    .done(function (data) {
      if (data === null) {
        console.log("Failed to get restaurants!");
      } else {
        handler(data);
      }
    })
    .fail(errorHandler);
}
admin.getGlobalRestaurants = getGlobalRestaurants;

admin.getUsersContaining = function (contains, handler, errorHandler) {
  get("superadminService/getUsersContaining?contains=" + encodeURIComponent(contains), localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

admin.getEntitiesContaining = function (entityType, contains, handler, errorHandler) {
  get("adminService/get" + entityType + "sContaining?contains=" + encodeURIComponent(contains), localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

admin.getEntitiesFiltered = function (entityType, filter, handler, errorHandler) {
  var filterQuery = "";
  Object.keys(filter).forEach(f => {
    filterQuery += "&" + f + "=" + filter[f];
  });
  get("adminService/" + localStorage.restaurantSelected + "/get" + entityType + "s?" + filterQuery, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

export const getStockStatistics = (filter, handler, errorHandler) => {
  var filterQuery = "";
  Object.keys(filter).forEach(f => {
    filterQuery += "&" + f + "=" + filter[f];
  });
  const serv = auth.server.indexOf("8444") === -1 ? auth.server.replace(/instance.eatwithme/g, "backup.eatwithme") : auth.server;
  get(serv + "/eatwithme.server/" + "adminService/" + localStorage.restaurantSelected + "/getStockStatistics?" + filterQuery, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}

export const getBookedTableOccupations = (filter, handler, errorHandler) => {
  var filterQuery = "";
  Object.keys(filter).forEach(f => {
    filterQuery += "&" + f + "=" + filter[f];
  });
  get("adminService/" + localStorage.restaurantSelected + "/getBookedTableOccupations?" + filterQuery, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}



export const getTableOccupations = handler => {
  get("adminService/" + localStorage.restaurantSelected + "/getTableOccupations?platform=" + auth.device.platform, localStorage.id).done(function (data) {
    if (data === null) {
      handler([]);
    } else {
      data.forEach(function (tableOccupation) {
        var restaurantTables = [];
        tableOccupation.restaurantTables.forEach(function (table, ind) {
          if (table.active) restaurantTables.push(table);
        });
        tableOccupation.restaurantTables = restaurantTables;
      });

      handler(data);
    }
  });
};
admin.getTableOccupations = getTableOccupations;

admin.getTableOccupation = function (tableOccupation, handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getTableOccupation/" + tableOccupation, localStorage.id).done(function (data) {
    if (data === null) {
      console.log("Failed to get table occupation!");
    } else {
      handler(data);
    }
  });
};

admin.getTableOccupationWithOrders = function (tableOccupation, handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getTableOccupation/" + tableOccupation + "?withOrders=true&lang=" + localStorage.language, localStorage.id).done(function (data) {
    if (data === null) {
      console.log("Failed to get table occupation!");
    } else {
      handler(data);
    }
  });
};

function getRestaurant(handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getRestaurant?lang=" + localStorage.language, localStorage.id).done(function (data) {
    if (data === null) {
      console.log("Failed to get restaurant!");
    } else {
      handler(data);
    }
  });
}
admin.getRestaurant = getRestaurant;

function getRestaurantLayout(handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getRestaurantLayout", localStorage.id).done(function (data) {
    //if (data === null) {
    //	ajaxErrorStart(admin_local.failed_to_get_restaurant_layout, error_code_500_img);
    //} else {
    handler(data);
    //}
  });
}
admin.getRestaurantLayout = getRestaurantLayout;

function modifyRestaurantFloorplan(id, name, floorplan, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/modifyRestaurantFloorplan", {
    id: id,
    name: name,
    isActive: active,
    floorplan: floorplan
  }).done(function (data) {
    post("adminService/" + localStorage.restaurantSelected + "/modifyTables", auth.myStatus.restaurant_tables.map(t => {
      if (t.roomId) t.room = { id: t.roomId }
      return t;
    })).done(() => {
      resultHandler(data, handler, errorHandler);
    })
  });
}
admin.modifyRestaurantFloorplan = modifyRestaurantFloorplan;

function addRestaurantFloorplan(name, floorplan, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/addRestaurantFloorplan", {
    name: name,
    isActive: active,
    floorplan: floorplan
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.addRestaurantFloorplan = addRestaurantFloorplan;

function deleteRestaurantFloorplan(id, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/deleteRestaurantFloorplan/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deleteRestaurantFloorplan = deleteRestaurantFloorplan;

function getRestaurantFloorplans(handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getRestaurantFloorplans", localStorage.id).done(function (data) {
    //if (data === null) {
    //	ajaxErrorStart(admin_local.failed_to_get_restaurant_layout, error_code_500_img);
    //} else {
    handler(data);
    //}
  });
}
admin.getRestaurantFloorplans = getRestaurantFloorplans;

function updateFloorPlan(floorplanId, handler) {
  get("adminService/" + localStorage.restaurantSelected + "/updateFloorPlan/" + floorplanId).done(function (data) {
    if (data === null) {
      console.log("Failed to upload floorplan!");
    } else {
      handler(data);
    }
  });
}
admin.updateFloorPlan = updateFloorPlan;

function getTables(handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getRestaurantTables", localStorage.restaurantSelected).done(function (data) {
    data.restaurantTables.forEach(t => t.roomId && (t.room = { id: t.roomId }));
    if (data === null) {
      console.log("Failed to get drink categories!");
    } else {
      handler(data);
    }
  });
}
admin.getTables = getTables;

admin.getRooms = function (handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getRestaurantRooms", localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get drink categories!");
    } else {
      handler(data);
    }
  });
};

function getActiveTableMates(handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getActiveTableMates", localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get table mates!");
    } else {
      admin.tableMates.length = 0;
      admin.tableMatesMap.clear();
      var tableMateSet = new SortedSet({
        comparator: function (a, b) {
          return a.id - b.id;
        }
      });

      data.forEach(function (mate) {
        if (tableMateSet.get(mate) === null) {
          admin.tableMates[admin.tableMates.length] = mate.id;
          admin.tableMatesMap.set(Number(mate.id), mate);
        }
      });

      //loadImagesForTableMates(tableMates, prevTableMates).done(function() {
      //		handler(data);
      //	});
    }
  });
}
admin.getActiveTableMates = getActiveTableMates;

export const isAdmin = roles => {
  if (typeof roles === "undefined") roles = auth.myStatus.roles;
  var result = false;
  roles.forEach(function (role) {
    if (role.role === "superadmin") result = true;
    // eslint-disable-next-line eqeqeq
    if (role.role === "admin" && role.entityType === "Restaurant" && role.entityId == localStorage.restaurantSelected) result = true;
  });
  return result;
};
admin.isAdmin = isAdmin;

export const hasRole = (roles, rolesChoice) => roles ? roles.filter(r => rolesChoice.filter(rr => r.role === rr).length > 0).length > 0 : false;
admin.hasRole = hasRole;

function modifyTable(id, room, number, name, seats, shareable, visibleOnline, bookOnline, active, type, freeSeats, pos, forStore, extensions, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/modifyTable", {
    id: id,
    number: number,
    name: name,
    seats: seats,
    shareable: shareable,
    visibleOnline: visibleOnline,
    bookOnline: bookOnline,
    isActive: active,
    type: type,
    freeSeats: freeSeats,
    pos: pos,
    forStore: forStore,
    room: room,
    extensions: extensions
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.modifyTable = modifyTable;

admin.modifyRoom = function (id, name, visibleOnline, bookOnline, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/modifyRoom", {
    id: id,
    name: name,
    visibleOnline: visibleOnline,
    bookOnline: bookOnline,
    isActive: active
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

function addTable(room, number, name, seats, shareable, visibleOnline, bookOnline, active, type, pos, forStore, extensions, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/addTable", {
    number: number,
    name: name,
    seats: seats,
    shareable: shareable,
    visibleOnline: visibleOnline,
    bookOnline: bookOnline,
    isActive: active,
    type: type,
    freeSeats: seats,
    pos: pos,
    forStore: forStore,
    room: room,
    extensions: extensions
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.addTable = addTable;

admin.addRoom = function (name, visibleOnline, bookOnline, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/addRoom", {
    name: name,
    visibleOnline: visibleOnline,
    bookOnline: bookOnline,
    isActive: active
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

function deleteTable(id, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/deleteTable/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deleteTable = deleteTable;

admin.deleteRoom = function (id, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/deleteRoom/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

admin.updateRoomOrder = function (rooms, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/updateRoomOrder", rooms).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

export const lockTable = (tableNumber, seat = 1, handler, errorHandler) => {

  const emptyOccupations = orderListUtil.tableOccupations.filter(t => t.restaurantTables.filter(tr => tr.restaurantTable && tr.restaurantTable.number === Number(tableNumber)).length > 0 && orderListUtil.ordersCache.filter(o =>
    o.tableOccupationId === t.id && o.state !== 'cancelled' && o.recordState !== 'DELETED'
  ).length === 0);
  if (getTable(tableNumber).pos && emptyOccupations.length > 0) {
    selectTableOccupation(emptyOccupations[0].id);
    return;
  }

  ajaxCallStart("lock-table");
  get("adminService/" + localStorage.restaurantSelected + "/lockTable/" + tableNumber + "?seats=" + seat + "&lang=" + localStorage.language + "&customerId=", undefined, undefined, 30000).done(function (data) {
    ajaxCallEnd("lock-table");
    resultHandler(data, handler, errorHandler);
  }).fail(data => {
    if (data.statusText === "timeout") {
      auth.ajaxError = false;
      get("adminService/" + localStorage.restaurantSelected + "/lockTable/" + tableNumber + "?seats=" + seat + "&lang=" + localStorage.language + "&customerId=", undefined, undefined, 30000).done(function (data) {
        ajaxCallEnd("lock-table");
        resultHandler(data, handler, errorHandler);
      }).fail(data => {
        if (data.status === 400) {
          auth.ajaxError = false;
          //eslint-disable-next-line no-undef
          if (auth.recoverFromError) {
            //eslint-disable-next-line no-undef
            auth.recoverFromError();
          }
        }
      })
    }
  });
};
//}
admin.lockTable = lockTable;

export const lockATable = () => {
  var customers = "";
  var count = admin.d.find("#seatsWanted").val();
  $(admin.d)
    .find("div.countSelector")
    .each(function (ind, item) {
      if (ind < count) {
        var customerId = $(item).attr("customerId");
        customers += "&customerId=" + customerId;
      }
    });
  get("adminService/" + localStorage.restaurantSelected + "/lockTable/" + admin.params.table + "?seats=" + admin.d.find("#seatsWanted").val() + "&lang=" + localStorage.language + customers).done(function (data) {
    resultHandler(data, admin.params.handler, admin.params.errorHandler);
  });
};
admin.lockATable = lockATable;

export const unlockTable = (occupation, table, cancelAll, handler, errorHandler) => {
  if (!occupation)
    return;
  get("adminService/" + localStorage.restaurantSelected + "/" + occupation + "/unlockTable/" + table + "?cancelAll=" + cancelAll + "&lang=" + localStorage.language)
    .done(data => resultHandler(data, handler, errorHandler))
    .fail(errorHandler);
};
admin.unlockTable = unlockTable;

admin.activateBooking = function (occupation, tableNumber, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/" + occupation + "/activateBooking/" + tableNumber).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

admin.unbookTable = function (occupation, table, cancelAll, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/" + occupation + "/unbookTable/" + table + "?cancelAll=" + cancelAll).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

export const unjoinTableMate = (tableMateId, handler, errorHandler) => {
  get("adminService/" + localStorage.restaurantSelected + "/unjoinTableMate/" + tableMateId).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.unjoinTableMate = unjoinTableMate;

export const incrementTableMates = (tableMateId, handler, errorHandler) => {
  get("adminService/" + localStorage.restaurantSelected + "/incrementTableMates/" + localStorage.tableOccupationSelected + "/" + localStorage.tableNumberSelected + "/" + tableMateId).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.incrementTableMates = incrementTableMates;

export const activateTableMate = (tableMateId, active, handler, errorHandler) => {
  get("adminService/" + localStorage.restaurantSelected + "/activateTableMate/" + tableMateId + "/" + active).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.activateTableMate = activateTableMate;

export const cancelOrder = (order, state, handler, errorHandler) => {
  // eslint-disable-next-line no-useless-concat
  post("adminService/" + localStorage.restaurantSelected + "/cancelOrder" + "?order=" + order, state).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
admin.cancelOrder = cancelOrder;

export const moveOrdersToCustomer = (orderIds, customer, state, timing, handler, errorHandler) => {
  if (typeof timing === "undefined") {
    timing = "asap";
  }
  // eslint-disable-next-line no-useless-concat
  var url =
    "adminService/" +
    localStorage.restaurantSelected +
    "/moveOrderToCustomer" +
    "?tableOccupation=" +
    localStorage.tableOccupationSelected +
    "&tableNumber=" +
    (localStorage.tableNumberSelected === "All" ? -1 : localStorage.tableNumberSelected) +
    "&lang=" +
    localStorage.language +
    "&order=" +
    orderIds +
    "&timing=" +
    timing +
    "&tableMateId=" +
    customer;
  post(url, state).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
admin.moveOrdersToCustomer = moveOrdersToCustomer;

/*
function moveOrderToDrinkAndQuantity(order, drink, quantity, state) {
  post(
      "adminService/moveOrderToDrinkAndQuantity" + "?tableOccupation=" + order.tableOccupationId + "&tableNumber="
          + (localStorage.tableNumberSelected === 'All' ? -1 : localStorage.tableNumberSelected) + "&tableMate="
          + (localStorage.mateSelected === 'All' || localStorage.mateSelected === 'IPay' ? -1 : localStorage.mateSelected) + "&lang=" + localStorage.language
          + "&drinkOrder=" + order.id + "&drink=" + drink + "&quantity=" + quantity, state).done(function(data) {
    updateOrders(data);
  }).fail(function(data) {
    updateOrders([]);
  });
}
admin.moveOrderToDrinkAndQuantity = moveOrderToDrinkAndQuantity;
*/

/*
function cancelAllOrders(tableOccupationId, question, state, keptstates) {
  $('.byCustomer.draggable.ordered').addClass('highlight');
  auth.confirmDialog(local.confirmation, question).done(
      function() {
        post(
            "adminService/cancelAllOrders" + "?tableOccupation=" + tableOccupationId + "&lang=" + localStorage.language + "&tableNumber="
                + (localStorage.tableNumberSelected === 'All' ? -1 : localStorage.tableNumberSelected) + "&tableMate="
                + (localStorage.mateSelected === 'All' || localStorage.mateSelected === 'IPay' ? -1 : localStorage.mateSelected), state).done(function(data) {
          $('.byCustomer.draggable.ordered').removeClass('highlight');
          preProcessOrders(data);
        });
      }).always(function(data) {
    $('.byCustomer.draggable.ordered').removeClass('highlight');
  });
}
admin.cancelAllOrders = cancelAllOrders;
*/

function defaultErrorHandler(data) {
  console.log("defaultErrorHandler");
  console.log(data);
}

export const resultHandler = (data, successHandler, errorHandler) => {
  if (data === null) {
    console.log("Failed to execute action");
    return;
  }
  var data2 = data;
  if (typeof data === "string" && data2.startsWith('{"'))
    data2 = JSON.parse(data2);
  if (data2.error) {
    // eslint-disable-next-line no-eval
    auth.messageDialog(local.error_message, eval(data2.error)).done(errorHandler ? errorHandler : defaultErrorHandler);
  } else {
    successHandler(data);
  }
};

function modifyRestaurant(data, handler, errorHandler) {
  post("adminService/" + data.id + "/modifyRestaurant", data).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.modifyRestaurant = modifyRestaurant;

export const getUsersContaining = (email, name, surname, phone, handler, errorHandler) => {
  get("adminService/getUsersContaining?email=" + encodeURIComponent(email) + "&name=" + encodeURIComponent(name) + "&surname=" + encodeURIComponent(surname) + "&phone=" + encodeURIComponent(phone), localStorage.id, undefined, undefined, false).done(data => {
    data.forEach(customer => {
      if (customer.email.indexOf("@") === -1)
        customer.email = "";
    });
    resultHandler(data, handler, errorHandler);
  });
}
admin.getUsersContaining = getUsersContaining;

function addNewRole(customerEmail, role, entityType, entityId, handler, errorHandler) {
  get("adminService/addNewRole?email=" + customerEmail + "&role=" + role + "&entityType=" + entityType + "&entityId=" + entityId, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  }).fail(data => {
    if (data.status === 400) {
      auth.ajaxError = false;
      auth.messageDialog(local.error_message, admin_local.user_missing);
    }
  });
}
admin.addNewRole = addNewRole;

function deleteRole(id, handler, errorHandler) {
  get("adminService/deleteRole?roleId=" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deleteRole = deleteRole;

function getProductionLines(handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getProductionLines", localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get production lines!");
    } else {
      handler(data);
    }
  });
}
admin.getProductionLines = getProductionLines;

function getPrinters(handler, restaurantId) {
  get("adminService/" + (typeof restaurantId === "undefined" ? localStorage.restaurantSelected : restaurantId) + "/getPrinters", localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get printers!");
    } else {
      handler(data);
    }
  });
}
admin.getPrinters = getPrinters;

function getPrintersStatus(handler, restaurantId) {
  get("adminService/" + (typeof restaurantId === "undefined" ? localStorage.restaurantSelected : restaurantId) + "/getPrintersStatus", localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get printers status!");
    } else {
      get("http://localhost:8080/printerService/getPrinters", "", undefined, undefined, false).done(() => {
        data.printerServerUrl = "http://localhost:8080/printerService"
        handler(data);
      }).fail(() => {
        auth.ajaxError = false;
        handler(data);
      })

    }
  });
}
admin.getPrintersStatus = getPrintersStatus;

function modifyPrinter(id, name, format, width, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/modifyPrinter", {
    id: id,
    name: name,
    isActive: active,
    width,
    format: format
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.modifyPrinter = modifyPrinter;

function addPrinter(name, format, width, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/addPrinter", {
    name: name,
    isActive: active,
    width,
    format: format
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.addPrinter = addPrinter;

function deletePrinter(id, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/deletePrinter/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deletePrinter = deletePrinter;

//bank terminals

function getScales(handler, restaurantId) {
  get("adminService/" + (typeof restaurantId === "undefined" ? localStorage.restaurantSelected : restaurantId) + "/getScales", localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get Scales!");
    } else {
      handler(data);
    }
  });
}
admin.getScales = getScales;

function getScalesStatus(handler, restaurantId) {
  get("adminService/" + (typeof restaurantId === "undefined" ? localStorage.restaurantSelected : restaurantId) + "/getScalesStatus", localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get Scales status!");
    } else {
      handler(data);
    }
  });
}
admin.getScalesStatus = getScalesStatus;

function modifyScale(id, name, format, type, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/modifyScale", {
    id: id,
    name: name,
    isActive: active,
    format: format,
    type: type
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.modifyScale = modifyScale;

function addScale(name, format, type, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/addScale", {
    name: name,
    isActive: active,
    format: format,
    type: type
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.addScale = addScale;

function deleteScale(id, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/deleteScale/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  }).fail(data => resultHandler(data, handler, errorHandler));
}
admin.deleteScale = deleteScale;

export const getBarcode = (id, handler, errorHandler) => {
  get("adminService/" + localStorage.restaurantSelected + "/getBarcode/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  }).fail(errorHandler);
}
admin.getBarcode = getBarcode;

function getVATCategories(handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getVATCategories", localStorage.restaurantSelected).done(function (data) {
    if (data === null) {
      console.log("Failed to get production lines!");
    } else {
      handler(data);
    }
  });
}
admin.getVATCategories = getVATCategories;

function modifyVATCategory(id, name, percent, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/modifyVATCategory", {
    id: id,
    name: name,
    percent: percent,
    isActive: active
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.modifyVATCategory = modifyVATCategory;

function addVATCategory(name, percent, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/addVATCategory", {
    name: name,
    percent: percent,
    isActive: active
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.addVATCategory = addVATCategory;

function deleteVATCategory(id, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/deleteVATCategory/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deleteVATCategory = deleteVATCategory;

function payForTableMate(id, payer, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/payForTableMate/" + id + "?payingMateId=" + payer).done(function (data) {
    if (data === null) {
      console.log("Failed to set paying customer!");
    } else {
      if (data.error) {
        // eslint-disable-next-line no-eval
        auth.messageDialog(local.error_message, eval(data.error));
      } else {
        handler(data);
      }
    }
  });
}
admin.payForTableMate = payForTableMate;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
admin.capitalizeFirstLetter = capitalizeFirstLetter;

function uncapitalizeFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}
admin.uncapitalizeFirstLetter = uncapitalizeFirstLetter;

export const getQuantity = o => {
  var r = "";
  if (typeof o.orders != "undefined") {
    r = o.orders.length;
  }
  var quantityType = o.quantityType ? o.quantityType : o.menuItem.quantityType;
  if (Number(o.quantity) === 1 && quantityType === "pcs") return r + "&nbsp;" + local[o.menuItem.quantityType];
  return r + " x " + o.quantity + " " + local[quantityType];
};
admin.getQuantity = getQuantity;

export const getProductionLine = id => {
  var r = "";
  auth.myStatus.restaurant_production_lines.forEach(function (p) {
    if (p.id === Number(id)) r = p;
  });
  return r;
};
admin.getProductionLine = getProductionLine;

export const updatePrices = categories => {
  if (categories.activeMenuItems)
    categories.activeMenuItems.forEach(function (menuitem) {
      if (menuitem.availableQuantities.length === 0) {
        if (menuitem.unitPrice > 0)
          menuitem.availableQuantities.push({
            price: menuitem.unitPrice,
            quantity: 1,
            fake: true,
            quantityType: {
              name: local.pcs
            }
          });
      } else {
        menuitem.availableQuantities.forEach(function (q) {
          // eslint-disable-next-line eqeqeq
          if (q.price == 0 && menuitem.unitPrice > 0) {
            q.price = q.quantity * menuitem.unitPrice;
          }
          q.label = !q.quantityType || q.quantityType.name === "N/A" ? "" : q.quantityType ? q.quantityType.name : "";
        });
      }
    });

  if (categories.activeMenuCategories)
    categories.activeMenuCategories.forEach(function (data) {
      updatePrices(data);
    });
};
admin.updatePrices = updatePrices;

export const getTableMate = id => {
  if (id === undefined) return id;
  if (id.id) return id;
  return admin.tableMatesMap.get(Number(id));
};
admin.getTableMate = getTableMate;


export const getTableMateForCustomer = id => {
  if (id === undefined) return id;
  if (id.id) return id;
  return Object.values(admin.tableMatesMap).find(m => {
    console.log(m);
    return m.tableMate && m.tableMate.id === id
  });
};
admin.getTableMate = getTableMateForCustomer;

admin.getTableMatesPaidBy = function (tableOccupationId, tableNumber, id) {
  var mates = [];
  admin.tableMates.forEach(function (mateId) {
    var mate = getTableMate(mateId);
    if (Number(mate.tableOccupationId) === Number(tableOccupationId) && Number(mate.tableNumber) === Number(tableNumber) && Number(mate.payingMateId) === Number(id)) mates.push(mate);
  });
  return mates;
};

function getTableMateByCustomer(id) {
  var res = {
    name: ""
  };
  admin.tableMatesMap.forEach(function (mate) {
    // eslint-disable-next-line eqeqeq
    if (typeof mate.tableMate !== "undefined" && mate.tableMate !== null && mate.tableMate.id == id) res = mate;
  });
  return res;
}
admin.getTableMateByCustomer = getTableMateByCustomer;

function downloadFloorPlan(handler) {
  // eslint-disable-next-line no-useless-concat
  get("restaurantService/" + localStorage.restaurantSelected + "/downloadFloorPlan", localStorage.restaurantSelected, {
    Accept: "text/html",
    "Content-Type": "application/json"
  }).done(function (data) {
    if (data === null) {
      console.log("Failed to download layout!");
    } else {
      localStorage.getDrinks = JSON.stringify(data);
      handler(data);
    }
  });
}
admin.downloadFloorPlan = downloadFloorPlan;

function getPaymentMethodsByRestaurant(restaurant, handler, errorHandler) {
  // eslint-disable-next-line no-useless-concat
  get("adminService/" + restaurant + "/getPaymentMethodsByRestaurant?lang=" + localStorage.language, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getPaymentMethodsByRestaurant = getPaymentMethodsByRestaurant;

function getAccessTokensByRestaurant(restaurant, handler, errorHandler) {
  // eslint-disable-next-line no-useless-concat
  get("api/accessTokens/search/findByRestaurant?restaurant=" + restaurant, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getAccessTokensByRestaurant = getAccessTokensByRestaurant;


function deleteAccessToken(id, handler, errorHandler) {
  // eslint-disable-next-line no-useless-concat
  del("api/accessTokens/" + id, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deleteAccessToken = deleteAccessToken;

function getCurrenciesByRestaurant(restaurant, handler, errorHandler) {
  // eslint-disable-next-line no-useless-concat
  get("adminService/" + restaurant + "/getCurrenciesByRestaurant?lang=" + localStorage.language, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getCurrenciesByRestaurant = getCurrenciesByRestaurant;

function getAllPaymentMethods(lang, handler, errorHandler) {
  if (typeof lang == "undefined") {
    lang = "";
  }
  // eslint-disable-next-line no-useless-concat
  get("adminService/" + localStorage.restaurantSelected + "/getAllPaymentMethods?lang=" + lang, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getAllPaymentMethods = getAllPaymentMethods;

function getAllCurrencies(lang, handler, errorHandler) {
  if (typeof lang == "undefined") {
    lang = "";
  }
  // eslint-disable-next-line no-useless-concat
  get("adminService/" + localStorage.restaurantSelected + "/getAllCurrencies?lang=" + lang, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getAllCurrencies = getAllCurrencies;

function addRestaurantCurrency(restaurant, currency, exchangeRate, active, handler, errorHandler) {
  get("adminService/" + restaurant + "/addRestaurantCurrency?currency=" + currency + "&exchangeRate=" + exchangeRate + "&active=" + active).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.addRestaurantCurrency = addRestaurantCurrency;

function modifyRestaurantCurrency(restaurant, id, currency, exchangeRate, active, handler, errorHandler) {
  get("adminService/" + restaurant + "/modifyRestaurantCurrency/" + id + "?currency=" + currency + "&exchangeRate=" + exchangeRate + "&active=" + active).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.modifyRestaurantCurrency = modifyRestaurantCurrency;

function deleteRestaurantCurrency(restaurant, id, handler, errorHandler) {
  get("adminService/" + restaurant + "/deleteRestaurantCurrency/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deleteRestaurantCurrency = deleteRestaurantCurrency;

function activateRestaurantPaymentMethod(restaurant, id, active, handler, errorHandler) {
  get("adminService/" + restaurant + "/activateRestaurantPaymentMethod/" + id + "?active=" + active).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.activateRestaurantPaymentMethod = activateRestaurantPaymentMethod;

function print(restaurant, printer, report, format, language, query, messageId, xml, handler, errorHandler) {
  /*
  if (printer == 'local') {

    var iframe = this._printIframe;
    if (!this._printIframe) {
      iframe = this._printIframe = document.createElement('iframe');
      document.body.appendChild(iframe);
      iframe.style.display = 'none';
      iframe.onload = function() {
        setTimeout(function() {
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };
      var html = $(iframe).contents().find('html');
      var h = $(html).html(xml);
      iframe.focus();
      iframe.contentWindow.print();
    }
    return;
  }
  */
  return auth.postString(
    "printerServerClient/" +
    restaurant +
    "/printBirtReport/" +
    printer +
    "?report=" +
    report +
    "&format=" +
    format +
    "&lang=" +
    language +
    "&query=" +
    encodeURIComponent(query) +
    "&messageId=" +
    encodeURIComponent(messageId),
    xml,
    { Accept: "text/html", "Content-Type": "text/html" }
  );
}
admin.print = print;

function printKitchenReceipt(printerOptions, xml, orderIds, pls, reprint, handler, errorHandler) {
  post("printerServerClient/" + localStorage.restaurantSelected + "/printKitchenReceipt?tableOccupation=" + localStorage.tableOccupationSelected + "&tableNumber=" + localStorage.tableNumberSelected, {
    //post("printerServerClient/" + localStorage.restaurantSelected + "/printKitchenReceipt/" + localStorage.tableOccupationSelected + "/" + localStorage.tableNumberSelected, {
    printerOptions: printerOptions,
    xml: xml,
    orderIds: orderIds,
    productionLines: pls,
    reprint: reprint,
    untilTiming: 'last',
    printQRCode: auth.myStatus.restaurant_settings["tables-overview"]["auto-table-qrcode"] ? printQRCode(true) : undefined
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.printKitchenReceipt = printKitchenReceipt;

function ordersPrinted(orderIds, handler, errorHandler) {
  get("printerServerClient/ordersPrinted?orderIds=" + orderIds).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.ordersPrinted = ordersPrinted;

function getQuantityTypes(handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getQuantityTypes", localStorage.restaurantSelected).done(function (data) {
    if (data == null) {
      console.log("Failed to get quantity types!");
    } else {
      handler(data);
    }
  });
}
admin.getQuantityTypes = getQuantityTypes;

function modifyQuantityType(id, name, active, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/modifyQuantityType", {
    id: id,
    name: name
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.modifyQuantityType = modifyQuantityType;

function addQuantityType(name, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/addQuantityType", {
    name: name
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.addQuantityType = addQuantityType;

function deleteQuantityType(id, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/deleteQuantityType/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deleteQuantityType = deleteQuantityType;

function getName(object, lang) {
  if (object[lang]) return object[lang];
  if (object.all) return object.all;
  return JSON.stringify(object);
}
admin.getName = getName;

function getUsersByFilter(contains, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/getUsersByFilter?contains=" + contains, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getUsersByFilter = getUsersByFilter;


function getUsersById(id, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/getUsersById/" + id, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getUsersById = getUsersById;

function setCustomerForTableMate(tableMateId, customerId, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/setCustomerForTableMate?tableMateId=" + tableMateId + "&customerId=" + customerId, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.setCustomerForTableMate = setCustomerForTableMate;

function setNewCustomerForTableMate(tableMateId, email, family_name, first_name, handler, errorHandler) {
  get(
    "adminService/" +
    localStorage.restaurantSelected +
    "/setNewCustomerForTableMate?tableMateId=" +
    tableMateId +
    "&email=" +
    email +
    "&first_name=" +
    first_name +
    "&family_name=" +
    family_name +
    "&lang=" +
    localStorage.language,
    localStorage.id
  ).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.setNewCustomerForTableMate = setNewCustomerForTableMate;

export const getDiscountTypes = (handler, errorHandler) => {
  get("adminService/" + localStorage.restaurantSelected + "/getDiscountTypes", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
admin.getDiscountTypes = getDiscountTypes;

function addDiscountType(discountType, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/addDiscountType", discountType).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.addDiscountType = addDiscountType;

function modifyDiscountType(discountType, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/modifyDiscountType", discountType).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.modifyDiscountType = modifyDiscountType;

function deleteDiscountType(discountType, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/deleteDiscountType/" + discountType).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deleteDiscountType = deleteDiscountType;

export const getAdvertisements = (handler, errorHandler) => {
  get("adminService/" + localStorage.restaurantSelected + "/advertisements", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
admin.getAdvertisements = getAdvertisements;

function addAdvertisement(advertisement, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/advertisements", advertisement).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.addAdvertisement = addAdvertisement;

function modifyAdvertisement(advertisement, handler, errorHandler) {
  patch("adminService/" + localStorage.restaurantSelected + "/advertisements/" + advertisement.id, advertisement).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.modifyAdvertisement = modifyAdvertisement;

function deleteAdvertisement(advertisement, handler, errorHandler) {
  del("adminService/" + localStorage.restaurantSelected + "/advertisements/" + advertisement).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.deleteAdvertisement = deleteAdvertisement;

export const getLabels = (handler, errorHandler) => {
  get("adminService/" + localStorage.restaurantSelected + "/getLabels", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
admin.getLabels = getLabels;

admin.updateDiscountsForTableOccupation = function (tableOccupationId, discounts, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/tableOccupation/" + tableOccupationId + "/updateDiscounts", discounts).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

export const getStores = (handler, errorHandler) => {
  get("adminService/" + localStorage.restaurantSelected + "/getStores", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.getStores = getStores;

export const getEntities = (entityName, handler, errorHandler) => {
  get("adminService/" + localStorage.restaurantSelected + "/get" + entityName, localStorage.id).done(function (data) {
    if ((entityName === "Stores") && auth.myStatus.roles.filter(role => role.role === "superadmin" || role.role === "admin").length === 0) {
      data = data.filter(entity => {
        return auth.myStatus.roles.filter(role => {
          console.log(role.entityType + "s", entityName, entity.id, role.entityId)
          return (role.entityType + "s") === entityName && entity.id === role.entityId
        }).length > 0
      })
    }
    resultHandler(data, handler, errorHandler);
  });
};
admin.getEntities = getEntities;

function getInvoiceXML(tableOccupation, tableNumber, tableMate, lang, copy = 1, fromdate = "", todate = "", handler, errorHandler) {
  try {
    if (NaN(copy)) {
      copy = 1;
    }
  } catch (ex) {
    console.error(ex);
  }
  if (getNonLocalPrinterForPrinterAction("printInvoice"))
    get(
      "printerServerClient/" + localStorage.restaurantSelected + "/getInvoiceXML/" + tableOccupation + "/" + tableNumber + "/" + tableMate + "?printer=" + getNonLocalPrinterForPrinterAction("printInvoice").id + "&lang=" + lang + "&copy=" + copy + "&fromdate=" + fromdate + "&todate=" + todate,
      localStorage.id,
      {
        Accept: "text/html",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    ).done(function (data) {
      resultHandler(data, handler, errorHandler);
    });
  else {
    resultHandler('{"success":"true"}', handler, errorHandler);
  }
}
admin.getInvoiceXML = getInvoiceXML;

function reprintInvoice(tableMatePaymentId, lang, handler, errorHandler) {
  get("printerServerClient/" + localStorage.restaurantSelected + "/reprintInvoice/" + tableMatePaymentId + "?printer=" + getNonLocalPrinterForPrinterAction("printInvoice").id + "&lang=" + lang, localStorage.id, {
    Accept: "text/html",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
admin.reprintInvoice = reprintInvoice;

admin.createNewTableOccupationForMate = function (tableOccupationId, oldTableNumber, mate, newTableNumber, allPaidMates, handler, errorHandler) {
  get(
    "adminService/" + localStorage.restaurantSelected + "/createNewTableOccupationForMate/" + tableOccupationId + "/" + oldTableNumber + "/" + mate + "/" + newTableNumber + "?allPaidMates=" + allPaidMates,
    localStorage.id
  ).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

admin.changeTableOccupationForMate = function (tableOccupationId, oldTableNumber, mate, newTableOccupationId, newTableNumber, allPaidMates, handler, errorHandler) {
  get(
    "adminService/" +
    localStorage.restaurantSelected +
    "/changeTableOccupationForMate/" +
    tableOccupationId +
    "/" +
    oldTableNumber +
    "/" +
    mate +
    "/" +
    newTableOccupationId +
    "/" +
    newTableNumber +
    "?allPaidMates=" +
    allPaidMates,
    localStorage.id
  ).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

admin.changeTableForMate = function (tableOccupationId, oldTableNumber, mate, tableNumber, allPaidMates, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/changeTableForMate/" + tableOccupationId + "/" + oldTableNumber + "/" + mate + "/" + tableNumber + "?allPaidMates=" + allPaidMates, localStorage.id).done(
    function (data) {
      resultHandler(data, handler, errorHandler);
    }
  );
};

admin.addAdditionalTable = function (tableOccupationId, tableNumber, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/addAdditionalTable/" + tableOccupationId + "/" + tableNumber, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

admin.changeToTable = function (tableOccupationId, oldTableNumber, newTableNumber, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/changeToTable/" + tableOccupationId + "/" + oldTableNumber + "/" + newTableNumber, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

var getCostAndPriceHistoryAtQueue = [];
var getCostAndPriceHistoryAtRunning = false;

admin.getCostAndPriceHistoryAt = function (menuItem, quantity, store, expiry, date, handler, errorHandler) {
  getCostAndPriceHistoryAtQueue.push({ menuItem, quantity, store, expiry, date, handler, errorHandler });
  if (!getCostAndPriceHistoryAtRunning) {
    getCostAndPriceHistoryAtRunning = true;
    setTimeout(admin._getCostAndPriceHistoryAt, 200);
  }
};

admin._getCostAndPriceHistoryAt = function () {
  var task = getCostAndPriceHistoryAtQueue;
  var request = getCostAndPriceHistoryAtQueue.map(r => { return { menuitem: r.menuItem, quantity: r.quantity, store: r.store, date: r.date } });
  getCostAndPriceHistoryAtQueue = [];
  post("adminService/" + localStorage.restaurantSelected + "/getCostAndPriceHistoriesAt", request, undefined, false).done(function (data) {
    task.forEach(t => {
      const r = data[t.menuItem + "-" + t.quantity + "-" + t.date + "-" + t.store];
      if (r) {
        resultHandler(data[t.menuItem + "-" + t.quantity + "-" + t.date + "-" + t.store], t.handler, t.errorHandler);
      } else {
        console.error("Missing from response: ", t);
      }
    })
    if (getCostAndPriceHistoryAtQueue.length > 0)
      admin._getCostAndPriceHistoryAt();
    else
      getCostAndPriceHistoryAtRunning = false;
  });
};

export const printHtml = (data, localPrinter) => {

  console.log("printer: " + localPrinter);

  const isPos = auth.myStatus.restaurant_printers.find(p => p.name === "local").format === "pos";

  const map = {
  }
  map["!ESC|rvC!ESC|cA!ESC|bC!ESC|uC!ESC|3C"] = [1, 2, 2];
  map["!ESC|rvC!ESC|cA"] = [0, 0, 1];
  map["!ESC|cA!ESC|4C"] = [1, 3, 1];
  map["!ESC|3C!ESC|bC"] = [0, 1, 1];
  map["!ESC|cA!ESC|bC!ESC|uC!ESC|3C"] = [3, 2, 1];
  map["!ESC|3C!ESC|bC"] = [1, 2, 0];
  map["!ESC|cA"] = [1, 1, 1];

  if (isPos && isNaN(localPrinter) && localPrinter.startsWith("epson/") && window.cordova.epos2) {
    window.cordova.epos2.disconnectPrinter()
      .then(function () {
        console.error("Local printer disconnected");
        // success callback
        print();
      })
      .catch(function (error) {
        // error callback
        print()
      });
    function print() {
      window.cordova.epos2.connectPrinter(localPrinter.split("/")[1], "TM-T20")
        .then(function () {
          console.error("Local printer connected");

          if (data.startsWith("data:image/png;base64,")) {
            console.error("Printing image", data);
            data.split("data:image/png;base64,").forEach(str => {
              if (str) {
                window.cordova.epos2.printImage("data:image/png;base64," + str, 0, 0, false);
              }
            })
            window.cordova.epos2.printText("\n", 0, 0, 0, true);
            return;
          }

          data = data.replace(/!SP/g, " ").replace(/!XX/g, "");
          data.split("!LF").forEach(txt => {
            var done = false;
            if (txt.startsWith("!ESCqrcode:"))
              return;
            Object.keys(map).forEach(key => {
              if (done)
                return;
              if (txt.startsWith(key)) {
                done = true;
                const m = map[key];
                window.cordova.epos2.printText(txt.substring(key.length), m[0], m[1], m[2], false);
              }
            })
            if (!done)
              window.cordova.epos2.printText(txt, 0, 0, 0, false);
            window.cordova.epos2.printText("\n", 0, 0, 0, false);


          });
          window.cordova.epos2.print(["\b"], function () {
            // success callback
          }, function (error) {
            // error callback
          });
          // success callback
        })
        .catch(function (error) {
          console.error("Failed to connect local printer " + localPrinter);
          console.error(error);
          alert(error);
          // error callback
        });
    }
    return;
  }
  if (isPos && isNaN(localPrinter) && localPrinter.startsWith("bt/")) {
    console.log("Print on bluetooth printer");
    localPrinter = localPrinter.split("/")[1];
    if (data.startsWith("data:image/png;base64,")) {
      console.log("Trying to print image", data);
      data.split("data:image/png;base64,").forEach(str => {
        if (str) {
          console.log("Print image", str);
          window.cordova.plugins.btprinter.printImage(localPrinter, "data:image/png;base64," + str, 30000,
            success => {
              console.log("Print Image ok");
            }, fail => {
              console.error(fail);
            });
        }
      });
    } else {
      console.error("Not an image", data);
    }
    return;
  }

  var iframe = /*this._printIframe = */ document.createElement("iframe");
  document.body.appendChild(iframe);
  iframe.style.display = "none";
  /*
  iframe.onload = function() {
    setTimeout(function() {
      iframe.focus();
      iframe.contentWindow.print();
      iframe.remove();
    }, 1000);
  };*/
  var iframeContents = $(iframe).contents();
  var html = iframeContents.find("html body");
  data = data.replace("\\<body", "<body onload='document.print();' ");
  var h = $(html).html(data);
  var tables = h.find("table");
  $(tables[0]).css("width", "100%");
  iframe.focus();

  setTimeout(function () {
    iframe.contentWindow.print();
    //iframe.remove();
  }, 1000);
};
admin.printHtml = printHtml;

function getEntitiesUrl(entity, handler, errorHandler) {
  // eslint-disable-next-line no-useless-concat
  return auth.server + "/eatwithme.server/" + "adminService/" + localStorage.restaurantSelected + "/get" + entity;
}
admin.getEntitiesUrl = getEntitiesUrl;

admin.initializeSelectDynamic = function (items) {
  $(items).each(function (ind, item) {
    var map = $(item).attr("map");
    var root = $(item).attr("root");
    map = JSON.parse(map);

    var processChildren = function (children) {
      if (typeof children == "undefined") return children;
      var a = [];
      children.forEach(function (d) {
        var v = {};
        Object.keys(map).forEach(function (key) {
          if ($.isArray(map[key])) {
            map[key].forEach(function (key2) {
              if (typeof d[key2] != "undefined") {
                var value = d[key2];
                if (value != null) v[key] = $.isArray(value) ? value : getLocale(value);
              }
            });
          } else {
            var value = d[map[key]];
            v[key] = $.isArray(value) ? value : getLocale(value);
          }
        });
        v.children = processChildren(v.children);
        a.push(v);
      });
      return a;
    };

    $(item).select2({
      ajax: {
        url: admin.getEntitiesUrl($(item).attr("entity")),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: localStorage.hash
        },
        processResults: function (data) {
          var a = [];
          if (root !== undefined) data = data[root];
          data.forEach(function (d) {
            var v = {};
            Object.keys(map).forEach(function (key) {
              if ($.isArray(map[key])) {
                map[key].forEach(function (key2) {
                  if (typeof d[key2] != "undefined") {
                    var value = d[key2];
                    if (value != null) v[key] = $.isArray(value) ? value : getLocale(value);
                  }
                });
              } else {
                var value = d[map[key]];
                v[key] = $.isArray(value) ? value : getLocale(value);
              }
            });
            v.children = processChildren(v.children);
            a.push(v);
          });
          return {
            results: a
          };
        }
      },
      dataType: "json"
    });
  });
};

var lists = {};

export const clearSelect2Cache = () => {
  lists = {}
}
export const getSelect2Cache = (entity) => {
  return lists[entity];
}

admin.initializeSelect2 = function (items, refresh) {
  var def = $.Deferred();
  if (items.length === 0) {
    def.resolve();
    return def;
  }
  var item = items[0];
  var entity = $(item).attr("entity");
  var sameItems = [];
  var remaingItems = [];

  $(items).each(function (ind, i) {
    if ($(i).attr("entity") === entity) sameItems.push(i);
    else remaingItems.push(i);
  });
  admin.initializeSelect3(sameItems, refresh).done(function () {
    admin.initializeSelect2(remaingItems, refresh).done(function () {
      def.resolve();
    });
  });

  return def.promise();
};

export const isInventoryItem = (data) => {
  return data.defaultInventoryItem;
}

function flatten(list, prefix) {
  if (!prefix) {
    prefix = "";
  }
  const result = [];
  list.forEach(l => {
    const newChild = { ...l };
    delete newChild.children;
    newChild.text = prefix + newChild.text;
    result.push(newChild);
    if (l.children)
      flatten(l.children, newChild.text + " -> ").forEach(l => result.push(l))
  })
  return result;
}

admin.initializeSelect3 = function (items, refresh) {
  var def = $.Deferred();
  var map = $(items[0]).attr("map");
  var root = $(items[0]).attr("root");
  var filter = $(items[0]).attr("filter");
  map = JSON.parse(map);

  var processChildren = function (children) {
    if (typeof children === "undefined") return children;
    var a = [];
    children.forEach(function (d) {
      // eslint-disable-next-line no-eval
      if (filter && !eval(filter)(d)) return;
      var v = {};
      Object.keys(map).forEach(function (key) {
        if (map[key] === ".") {
          v[key] = key !== "id" && typeof local[d] !== "undefined" ? local[d] : d;
          return;
        }
        if (Array.isArray(map[key])) {
          map[key].forEach(function (key2) {
            if (Array.isArray(key2)) {
              var value = key2.map(key3 => {
                var value = d[key3];
                if (value !== null) return Array.isArray(value) ? value : getLocale(value);
                return "";
              }).filter(k => k && k.trim()).join(" / ");
              if (value) v[key] = value;
            } else if (typeof d[key2] !== "undefined") {
              var value = d[key2];
              if (value !== null) v[key] = Array.isArray(value) ? value : getLocale(value);
            }
          });
        } else {
          var value = d[map[key]];
          v[key] = Array.isArray(value) ? value : getLocale(value);
        }
      });
      v.children = processChildren(v.children);
      a.push(v);
    });
    return a;
  };

  var processResults = function (data) {
    var a = [];
    if (root !== undefined) {
      data = data[root];
    }
    if (!Array.isArray(data)) return a;
    data.forEach(function (data) {
      var v = {};
      Object.keys(map).forEach(function (key) {
        if (map[key] === ".") {
          v[key] = key !== "id" && typeof local[data] !== "undefined" ? local[data] : data;
          return;
        } else if (Array.isArray(map[key])) {
          map[key].forEach(function (key2) {
            if (Array.isArray(key2)) {
              var value = key2.map(key3 => {
                var value = data[key3];
                if (value !== null) return Array.isArray(value) ? value : getLocale(value);
                return "";
              }).filter(k => k && k.trim()).join(" / ");
              if (value) v[key] = value;
            } else if (typeof data[key2] != "undefined") {
              var value = data[key2];
              if (value !== null) v[key] = Array.isArray(value) ? value : getLocale(value);
            }
          });
        } else if (map[key].startsWith("$")) {
          // eslint-disable-next-line no-eval
          v[key] = eval(map[key].substring(1));
        } else {
          var value = data[map[key]];
          v[key] = Array.isArray(value) ? value : getLocale(value);
        }
      });
      v.children = processChildren(v.children);
      if (!v.children || v.children.length > 0 || root === map['children']) {
        a.push(v);
      }

      processResults(data).forEach(c => {
        a.push(c);
      });
    });
    return a;
  };
  var entity = $(items[0]).attr("entity");
  var children = map.children;
  var allowClear = $(items[0]).attr("allowClear");
  var allowNew = $(items[0]).attr("allowNew");
  var flat = $(items[0]).attr("flat");
  var type = typeof lists[entity + "." + children];
  if (type === "undefined" || refresh) {
    lists[entity + "." + children] = $.Deferred();
    admin.getEntities(
      entity,
      function (data) {
        var def2 = lists[entity + "." + children];
        lists[entity + "." + children] = processResults(data);

        let list = [...lists[entity + "." + children]];
        if (allowNew) {
          list.splice(0, 0, { id: -1, text: "+++ " + I18n.t('admin_local.new') + " +++" });
        }
        if (allowClear) {
          list.splice(0, 0, { id: null, text: "---" });
        }

        if (flat)
          list = flatten(list);

        if (map.selectableGroup === "true") {
          $(items).select2({
            data: list,
            allowClear: allowClear,
            width: $(items[0]).attr("width"),
            minimumResultsForSearch: $(items[0]).attr("minimumResultsForSearch"),
            theme: "default " + $(items[0]).attr("selectionCssClass"),
          });
        } else {
          $(items).select2({
            data: list,
            allowClear: allowClear,
            width: $(items[0]).attr("width"),
            minimumResultsForSearch: $(items[0]).attr("minimumResultsForSearch"),
            theme: "default " + $(items[0]).attr("selectionCssClass"),
          });
        }
        /*
      $(items).next().each((ind,item) => {
        item.addEventListener("touchstart", event => {
          event.preventDefault();
        });
      });*/

        if ($(items[0]).attr("allowNull")) {
          $(items).val(null);
          $(items).trigger("change");
        }
        def2.resolve(lists[entity + "." + children]);
        def.resolve();
      },
      () => {
        var def2 = lists[entity + "." + children];
        lists[entity + "." + children] = undefined;
        def2.reject();
      }
    );
  } else if (lists[entity + "." + children].done) {
    lists[entity + "." + children].done(data => {
      let list = [...lists[entity + "." + children]];
      if (allowNew) {
        list.splice(0, 0, { id: -1, text: "+++ " + I18n.t('admin_local.new') + " +++" });
      }
      if (allowClear) {
        list.splice(0, 0, { id: null, text: "---" });
      }
      if (flat)
        list = flatten(list);

      $(items).select2({
        data: list,
        allowClear: $(items[0]).attr("allowClear"),
        width: $(items[0]).attr("width"),
        theme: "default " + $(items[0]).attr("selectionCssClass"),
      });


      /*
      $(items).next().each((ind,item) => {
        item.addEventListener("touchstart", event => {
          event.preventDefault();
        });
      });*/
      if ($(items[0]).attr("allowNull")) {
        $(items).val(null);
        $(items).trigger("change");
      }
      def.resolve();
    });
  } else {
    let list = [...lists[entity + "." + children]];
    if (allowNew) {
      list.splice(0, 0, { id: -1, text: "+++ " + I18n.t('admin_local.new') + " +++" });
    }
    if (allowClear) {
      list.splice(0, 0, { id: null, text: "---" });
    }
    if (flat)
      list = flatten(list);

    $(items).select2({
      data: list,
      allowClear: $(items[0]).attr("allowClear"),
      width: $(items[0]).attr("width"),
      theme: "default " + $(items[0]).attr("selectionCssClass"),
    });

    if ($(items[0]).attr("allowNull")) {
      $(items).val(null);
      $(items).trigger("change");
    }
    def.resolve();
  }
  return def.promise();
};

export const printPdf = (report, format, language, url, messageId, xml, printer) => {
  if (typeof printer === "undefined") printer = "local";

  if (printer === "local") {
    //var iframe = this._printIframe;
    //if (!this._printIframe) {
    const iframe = /*this._printIframe = */ document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
        iframe.remove();
      }, 1);
    };
    //}

    if (xml != null) {
      admin.print(localStorage.restaurantSelected, printer, report, format, language, url, messageId, xml).done(data => {
        var html = $(iframe)
          .contents()
          .find("html body");
        var h = $(html).html(data);
        var tables = h.find("table");
        $(tables[0]).css("width", "100%");
        iframe.focus();
        setTimeout(function () {
          iframe.contentWindow.print();
        }, 100);
      });
    } else
      get(url).done(function (data) {
        var html = $(iframe)
          .contents()
          .find("html body");
        var h = $(html).html(data);
        var tables = h.find("table");
        $(tables[0]).css("width", "100%");
        iframe.focus();
        setTimeout(function () {
          iframe.contentWindow.print();
        }, 100);
      });
    return;
  } else admin.print(localStorage.restaurantSelected, printer, report, format, language, url, messageId, xml);
};
admin.printPdf = printPdf;

export const getPriceForQuantity = (menuItem, quantity, takeaway = false, child = false) => {
  var price = menuItem.unitPrice * quantity;
  menuItem.availableQuantities.forEach(function (q) {
    var pp = q.price;
    if (auth.myStatus.restaurant_settings.takeaway.price) {
      if (takeaway) {
        if (q.settings && q.settings.takeaway && q.settings.takeaway.takeaway && q.settings.takeaway.takeaway.price) {
          pp = q.settings.takeaway.takeaway.price;
        }
      }
    }
    // eslint-disable-next-line eqeqeq
    if (q.quantity == quantity && pp != null && q.price != "") {
      if (("" + pp).indexOf("%") >= 0) {
        var percent = ("" + pp).split("%")[0];
        price = Math.round((price * percent) / 100);
      } else {
        price = Number(pp);
      }
    }
  });
  const additions = getMenuItemAdditions(menuItem, '', 'mandatory', takeaway);
  additions.forEach(a => {
    const m = getMenuItem(a.id);
    if (m && a.local != a.takeaway) {
      const p = (a.proportional ? getPriceForQuantity(m, quantity, takeaway, true) : getPriceForQuantity(m, 1, takeaway, true));
      price += p;
    }

  })
  const rounding = (auth.myStatus.restaurant_settings["price-calculations"].rounding);
  if (!child && rounding) {
    price = Math.ceil(price / rounding) * rounding;
  }
  return Math.round(price);
};
admin.getPriceForQuantity = getPriceForQuantity;

export const getNameForQuantity = (menuItem, quantity) => {
  var name = "";
  menuItem.availableQuantities.forEach(function (q) {
    // eslint-disable-next-line eqeqeq
    if (q.quantity == quantity) {
      name = getLocale(q.quantityType.name);
    }
  });
  return name;
};
admin.getNameForQuantity = getNameForQuantity;


export const getDiscount = id => {
  var ret = null;
  auth.myStatus.restaurant_discounts.forEach(function (discount) {
    // eslint-disable-next-line eqeqeq
    if (discount.id == id) ret = discount;
  });
  if (ret == null)
    return {};
  return ret;
};
admin.getDiscount = getDiscount;

var localPrinterSettings = {
  productionLines: {},
  printerActions: {}
};
if (typeof localStorage.localPrinterSettings != "undefined") localPrinterSettings = JSON.parse(localStorage.localPrinterSettings);

export const getLocalPrinterSettings = () => {
  return localPrinterSettings;
};

export const storeLocalPrinterSettings = () => {
  localStorage.localPrinterSettings = JSON.stringify(localPrinterSettings);
};

export const getPrinter = printer => {
  var pr;
  auth.myStatus.restaurant_printers.forEach(function (p) {
    // eslint-disable-next-line eqeqeq
    if (p.id == printer) pr = p;
  });
  return pr;
};
admin.getPrinter = getPrinter;

export const getPrinterForProductionLine = productionLine => {
  if (productionLine.printer == null) return null;
  if (localPrinterSettings.productionLines["pl_" + productionLine.id]) {
    if (isNaN(localPrinterSettings.productionLines["pl_" + productionLine.id]))
      return { name: localPrinterSettings.productionLines["pl_" + productionLine.id], id: localPrinterSettings.productionLines["pl_" + productionLine.id], connectedPrinter: "connected" };
    const printer = getPrinter(localPrinterSettings.productionLines["pl_" + productionLine.id]);
    if (printer)
      return getPrinter(localPrinterSettings.productionLines["pl_" + productionLine.id]);
  }
  return productionLine.printer;
};
admin.getPrinterForProductionLine = getPrinterForProductionLine;

export const getPrinterForPrinterAction = (action) => {
  if (localPrinterSettings.printerActions === undefined) {
    localPrinterSettings.printerActions = {};
  }
  if (localPrinterSettings.printerActions["pa_" + action]) {
    if (isNaN(localPrinterSettings.printerActions["pa_" + action])) {
      return { name: localPrinterSettings.printerActions["pa_" + action], id: localPrinterSettings.printerActions["pa_" + action], connectedPrinter: "connected" };
    }
    const printer = getPrinter(localPrinterSettings.printerActions["pa_" + action]);
    if (printer)
      return printer;
  }

  return auth.myStatus.restaurant_printer_actions[action];
};
admin.getPrinterForPrinterAction = getPrinterForPrinterAction;

export const getNonLocalPrinterForPrinterAction = (action) => {
  if (localPrinterSettings.printerActions === undefined) {
    localPrinterSettings.printerActions = {};
  }
  if (auth.myStatus.restaurant_printer_actions[action] && localPrinterSettings.printerActions["pa_" + action]) {
    if (isNaN(localPrinterSettings.printerActions["pa_" + action])) {
      return auth.myStatus.restaurant_printers.find(p => p.name === "local")
    }
    const printer = getPrinter(localPrinterSettings.printerActions["pa_" + action]);
    if (printer)
      return printer;
  }

  return auth.myStatus.restaurant_printer_actions[action];
}
admin.getNonLocalPrinterForPrinterAction = getNonLocalPrinterForPrinterAction;

admin.getTable = function (number) {
  var res = null;
  auth.myStatus.restaurant_tables.forEach(function (table) {
    // eslint-disable-next-line eqeqeq
    if (table.number == number) res = table;
  });
  return res;
};
admin.getRoom = function (roomId) {
  var res = null;
  auth.myStatus.restaurant_rooms.forEach(function (room) {
    // eslint-disable-next-line eqeqeq
    if (room.id == roomId) res = room;
  });
  return res;
};

var lockuserdef;

export const unlock = (name, id) => {
  lockuserdef = new $.Deferred();
  const n = name;
  if (typeof name == "undefined" || name === "new") {
    name = name === "new" ? I18n.t("admin_local.addlogin") : "";
    id = "";
  }
  auth.quickAccessCode = "";
  $(".modal-backdrop.show").remove();
  $("div#getCode.modal").remove();
  if ($("div#getCode.modal").length === 0) {
    $("script#getCode")
      .tmpl({ name: name, id: id, ...tmplparams() })
      .appendTo("body");
    $("div#getCode.modal button").click(function (event) {
      if (auth.quickAccessCodeTimeout)
        clearTimeout(auth.quickAccessCodeTimeout);
      auth.quickAccessCodeTimeout = setTimeout(() => {
        auth.quickAccessCode = "";
        auth.quickAccessCodeTimeout = null;
      }, 4000);

      auth.quickAccessCode += "" + $(event.target).attr("id");
      if (auth.quickAccessCode.length > 2)
        get("login/changeSession?user=-1&quickCode=" + auth.quickAccessCode)
          .done(function (data, status) {
            if (data.error && data.error === "no match") {
              return;
            }
            getGlobal("login/changeSession?user=-1&quickCode=" + auth.quickAccessCode).done(() => {
              auth.myStatus.id = localStorage.id = data.customer.id;
              auth.myStatus.name = localStorage.name = data.customer.name;
              auth.myStatus.surname = localStorage.surname = data.customer.surname;
              auth.myStatus.email = localStorage.email = data.customer.email;
              auth.myStatus.global_id = data.customer.globalId;
              auth.myStatus.roles = data.roles;
              localStorage.removeItem("myImage");
              localStorage.locked = false;
              if (typeof auth.myStatus !== "undefined") auth.myStatus.customers = data.customers;
              if (typeof admin.userChanged !== "undefined") admin.userChanged();
              if (lockuserdef)
                lockuserdef.resolve();
              $("div#getCode.modal").modal("hide");
              $(".modal-backdrop.fade.show").remove();
            })

          })
          .fail(function (data) {
            auth.ajaxError = false;
            if (lockuserdef)
              lockuserdef.reject();
          });
    });
  }
  $("div#getCode.modal").modal("show");
  select($('div#getCode.modal .selectable[level= "0"]'));
  if (n === "new") {
    if ($('#loginForm').css('width') === '0px') {
      $('#loginForm').css('width', '100%');
      $('#loginForm').css('height', '100%');
    } else {
      $('#loginForm').css('width', '0px');
      $('#loginForm').css('height', '0px');
    }
    $('#getCode #numbersBlock').addClass("hidden");
    $('#getCode #login').addClass("hidden");
    $('#getCode .changeuser').addClass("hidden");
    $('#loginForm input#email').focus();
  }
  $("div#getCode.modal").on("hide.bs.modal", () => {
    if (lockuserdef != null)
      lockuserdef.reject();
    lockuserdef = null;
  })
  return lockuserdef.promise();
};

export const getPickupTableNumber = () => {
  return auth.myStatus.restaurant_settings["enabled-features"].pickup["table-number"];
}

export const getHomeDeliveryTableNumber = () => {
  return auth.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"];
}

export const getFeature = (feature) => {
  return auth.myStatus.restaurant_settings["enabled-features"][feature];
}

export const lock = () => {
  if (localStorage.locked !== "true") {
    if ($("html[dontlock]").length === 0) {
      if (typeof admin.hideEditTable !== "undefined") admin.hideEditTable(true);
      //$('.modal').modal("hide");
      if (auth.myStatus.restaurant_settings["tables-overview"].autolock) {
        localStorage.locked = true;
        $("div#locked").show();
      }
      // localStorage.removeItem('hash');
    }
  }
};

export const unlockme = () => {
  lock();
  unlock();
};

export const quickChangeToUser = quickAccessCode => {
  const lockuserdeff = lockuserdef;
  lockuserdef = null;
  get("login/changeSession?user=-1&quickCode=" + quickAccessCode)
    .done(function (data) {
      if (data.error && data.error === "no match") {
        return;
      }
      $("div#getCode.modal").modal("hide");
      auth.myStatus.id = localStorage.id = data.customer.id;
      auth.myStatus.name = localStorage.name = data.customer.name;
      auth.myStatus.surname = localStorage.surname = data.customer.surname;
      auth.myStatus.email = localStorage.email = data.customer.email;
      auth.myStatus.roles = data.roles;
      localStorage.removeItem("myImage");
      localStorage.locked = false;
      auth.myStatus.global_id = data.customer.globalId;
      if (typeof auth.myStatus != "undefined") auth.myStatus.customers = data.customers;
      if (typeof admin.userChanged !== "undefined") admin.userChanged();
      if (lockuserdeff) {
        lockuserdeff.resolve();
      }
    })
    .fail(function (data) {
      auth.ajaxError = false;
    });
};

export const changeToUser = (name, id) => {
  const lockuserdeff = lockuserdef;
  lockuserdef = null;
  auth.quickAccessCode = "";
  const globalId = auth.myStatus.customers.find(c => c.id === id).globalId;
  getGlobal("login/changeSession?user=" + globalId + "&quickCode=").done(function (data, status) {
    if (data.error && data.error === "no match") {
      if (lockuserdeff) {
        lockuserdeff.reject();
      }
      unlock(name, id);
    } else {
      get("login/changeSession?user=" + id + "&quickCode=").done(function (data, status) {
        $("div#getCode.modal").modal("hide");
        auth.myStatus.id = localStorage.id = data.customer.id;
        auth.myStatus.name = localStorage.name = data.customer.name;
        auth.myStatus.surname = localStorage.surname = data.customer.surname;
        auth.myStatus.email = localStorage.email = data.customer.email;
        auth.myStatus.global_id = data.customer.globalId;
        auth.myStatus.roles = data.roles;
        localStorage.removeItem("myImage");
        localStorage.locked = false;
        if (typeof auth.myStatus !== "undefined") {
          getMyStatus(data => {
            if (typeof admin.userChanged != "undefined") admin.userChanged();
            if (lockuserdeff) {
              lockuserdeff.resolve();
            }
          });
        }
      });
    }
  });
};

export const updateTimeahead = (type, timeahead) => {
  get("adminService/" + localStorage.restaurantSelected + "/updateTimeahead/" + type + "/" + timeahead).done(() => auth.myStatus.restaurant_settings["enabled-features"][type].timeahead = Math.round(timeahead / 60 * 100) / 100);
}

export default admin;


export const getQuantityForCount = (menuItem, quantity, count) => {

  if (menuItem.freeQuantity) {
    quantity = quantity * count;
    count = 1;
  }

  var quantityType = menuItem.availableQuantities.find(q => Math.abs(q.quantity) === Math.abs(quantity));

  if (!quantityType)
    quantityType = menuItem.availableQuantities[0];

  if (!quantityType)
    return quantity + "x" + count;

  var qtype = menuItem.quantityType;

  switch (qtype) {
    case "g":
      if (quantity >= 10) {
        quantity = quantity / 10;
        qtype = "dkg";
      }
      if (quantity >= 100) {
        quantity = quantity / 100;
        qtype = "kg";
      }
      break;
    case "dkg":
      if (quantity >= 100) {
        quantity = quantity / 100;
        qtype = "kg";
      }
      break;
    case "ml":
      if (quantity >= 10) {
        quantity = quantity / 10;
        qtype = "cl";
      }
      if (quantity >= 10) {
        quantity = quantity / 10;
        qtype = "dl";
      }
      if (quantity >= 10) {
        quantity = quantity / 10;
        qtype = "l";
      }
      break;
    case "cl":
      if (quantity >= 10) {
        quantity = quantity / 10;
        qtype = "dl";
      }
      if (quantity >= 10) {
        quantity = quantity / 10;
        qtype = "l";
      }
      break;
    case "dl":
      if (quantity >= 10) {
        quantity = quantity / 10;
        qtype = "l";
      }
      break;
    default:
  }

  var label = local[quantityType.name] ? local[quantityType.name] : quantityType.name;

  var label2 = (Math.abs(quantity) === 1 && qtype === 'pcs') ? '' : (((quantity) + (quantity === 1 && qtype === 'pcs' ? '' : qtype === '' ? "" : ' ' + local[qtype])));

  if (!label && !label2) {
    label2 = local[menuItem.quantityType];
  }


  if (count === 1)
    return (label ? label + " (" + label2 + ")" : label2);
  else
    return count + " x " + (label ? label + " (" + label2 + ")" : label2);
}

export const isPickup = () => Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].pickup["table-number"];
export const isHomeDelivery = () => Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"];

export const settings = context => {
  const settings = $("<div class='settings'/>");
  settings.appendTo($("body"));
  switch (context) {
    case "settings":
      ReactDOM.render(<SettingsEditor
        settings={auth.myStatus.restaurant_settings}
        schema={restaurant_settings_schema(auth.myStatus.restaurant_languages, auth.myStatus, true)}
        uiSchema={restaurant_settings_schema_ui_schema(auth.myStatus.restaurant_languages, true)}
        container={settings.get(0)}
        path=""
        property=""
      />,
        settings.get(0));
      break;
    case "editTable":
      if (isHomeDelivery())
        ReactDOM.render(<SettingsEditor
          settings={auth.myStatus.restaurant_settings["enabled-features"].homedelivery}
          schema={restaurant_settings_schema(auth.myStatus.restaurant_languages, auth.myStatus, true).properties["enabled-features"].properties.homedelivery}
          uiSchema={restaurant_settings_schema_ui_schema(auth.myStatus.restaurant_languages, true)["enabled-features"].homedelivery}
          container={settings.get(0)}
          path="enabled-features"
          property="homedelivery"
        />,
          settings.get(0));
      else if (isPickup()) {
        ReactDOM.render(<SettingsEditor
          settings={auth.myStatus.restaurant_settings["enabled-features"].pickup}
          schema={restaurant_settings_schema(auth.myStatus.restaurant_languages, auth.myStatus, true).properties["enabled-features"].properties.pickup}
          uiSchema={restaurant_settings_schema_ui_schema(auth.myStatus.restaurant_languages, true)["enabled-features"].pickup}
          container={settings.get(0)}
          path="enabled-features"
          property="pickup"
        />,
          settings.get(0));
      } else {
        ReactDOM.render(<SettingsEditor
          settings={auth.myStatus.restaurant_settings["tables-overview"]}
          schema={restaurant_settings_schema(auth.myStatus.restaurant_languages, auth.myStatus, true).properties["tables-overview"]}
          uiSchema={restaurant_settings_schema_ui_schema(auth.myStatus.restaurant_languages, true)["tables-overview"]}
          container={settings.get(0)}
          path=""
          property="tables-overview"
        />,
          settings.get(0));
      }
      break;
    case "preBooking":
      ReactDOM.render(<SettingsEditor
        settings={auth.myStatus.restaurant_settings["enabled-features"].prebooking}
        schema={restaurant_settings_schema(auth.myStatus.restaurant_languages, auth.myStatus, true).properties["enabled-features"].properties.prebooking}
        uiSchema={restaurant_settings_schema_ui_schema(auth.myStatus.restaurant_languages, true)["enabled-features"].prebooking}
        container={settings.get(0)}
        path="enabled-features"
        property="prebooking"
      />,
        settings.get(0));
      break;
    default:
      settings.get(0).remove();
  }
}
export const selectTableOccupationSelected = (id) => {
  localStorage.tableOccupationSelected = id;
}

export const selectTableNumberSelected = (id) => {
  localStorage.tableNumberSelected = id;
}